<template>
  <div v-loading="table.loading" class="formula-comment p-2 w-100 h-120 flex flex-col rounded border border-gray-200">
    <en-scrollbar class="h-80 border-b-gray" noresize>
      <div v-for="item of table.data" :key="item.id" class="p-3 border-b border-b-gray-200">
        <div class="flex items-center gap-5">
          <span class="text-lg">{{ item.userName }}</span>
          <span class="flex-1 text-gray-500 text-sm">{{ item.tenantName }}</span>
          <span class="text-sm">{{ formatDate(item.preparedDatetime) }}</span>
        </div>
        <div class="flex items-end">
          <span class="flex-1 break-all text-sm">{{ item.comment }}</span>
          <button-delete v-if="store.user.ssoUserId === item.ssoUserId" :method="table.operation.delete.click" :params="item">删除</button-delete>
        </div>
      </div>

      <div v-if="!table.data.length" class="flex justify-center pt-20">暂无评论</div>
    </en-scrollbar>

    <div class="flex-1 p-3">
      <en-input
        v-model="form.data.comment"
        type="textarea"
        placeholder="你的评论..."
        :disabled="!data?.id"
        @keyup.enter.native="form.operation.submit.click"
      ></en-input>
      <en-button type="primary" :disabled="!data?.id || table.loading || form.loading" @click="form.operation.submit.click" class="float-right"
        >发送</en-button
      >
    </div>
  </div>
</template>

<script lang="ts">
export default factory({
  props: {
    data: Object as PropType<EnospraySprayDefinitions['SprayFormulaDto']>
  },

  watch: {
    'data.id': {
      handler(id) {
        if (id) this.table.get()
        else this.table.data = []
      },
      immediate: true
    }
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/formula/:formulaId/comments',
            loading: true,
            data: 'array',
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          delete: {
            action: 'DELETE /enospray/formula/:formulaCommentId/comment',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnospraySprayDefinitions['SprayFormulaCommentDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          }
        }
      },
      form: {
        data: {
          comment: ''
        },
        ajax: {
          submit: {
            action: 'POST /enospray/formula/:formulaId/comment',
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
              params.data = [this.form.data.comment]
            }
          }
        },
        children: {
          operation: {
            submit: {
              async click() {
                if (!this.form.data.comment) return
                await this.form.submit()
                await this.table.get()
                this.form.data.comment = ''
              }
            }
          }
        }
      }
    }
  }
})
</script>

<style scoped>
.formula-comment::deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.formula-comment::deep .el-textarea__inner {
  border: none;
}
</style>
